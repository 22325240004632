.cookiebanner {
    position: fixed;
    z-index: 200;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    padding: 10px;
    background-color: $color-green;
    line-height: 20px;
    text-align: center;
    @include transition(top 0.2s);

    &__link {
        margin: 10px 10px;
    }

    &.show {
        z-index: 15000;
        display: block;

        ~ .l-site-header {
            margin-top: 130px;

            @include media-breakpoint-up(sm) {
                margin-top: 110px;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 76px;
            }
        }
    }
}
