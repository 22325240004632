.qty-picker {
    width: fit-content;
    border: 1px solid $color-primary;
    border-radius: 4px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        max-width: 208px;
    }

    &__int {
        max-width: 80px;
        border-top: none;
        border-right: 1px solid $color-primary;
        border-bottom: none;
        border-left: 1px solid $color-primary;
        border-radius: 0;
    }

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }
}
