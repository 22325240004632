.prose,
.mce-content-body {
    > * + * {
        margin-top: 1em;
        margin-bottom: 0;
    }

    .textblock & {
        max-width: 100%;
    }

    &--wide {
        max-width: 100%;
    }

    h2:not(.accordion-dropdown__header),
    h3,
    h4 {
        line-height: $leading-snug;
    }

    h2:not(.accordion-dropdown__header) {
        font-size: $text-3xl;

        &.text-4xl {
            font-size: $text-4xl;
        }
    }

    h3 {
        font-size: $text-2xl;
    }

    h4 {
        font-size: $text-xl;
    }

    @include media-breakpoint-up($bp-desktop-fonts) {
        h2:not(.accordion-dropdown__header) {
            font-size: $text-d-3xl;

            &.text-4xl {
                font-size: $text-d-4xl;
            }
        }

        h3 {
            font-size: $text-d-2xl;
        }

        h4 {
            font-size: $text-d-xl;
        }
    }

    @at-root h1 + & {
        margin-top: 1em;
    }

    h2:not(.accordion-dropdown__header),
    h3,
    h4 {
        + * {
            margin-top: 0.75em;
        }
    }

    @at-root .preamble + & {
        margin-top: 2em;
    }

    p + h2:not(.accordion-dropdown__header),
    p + h3,
    p + h4 {
        margin-top: 1.25em;
    }

    p {
        line-height: $leading-relaxed;
    }

    > * + {
        & ul,
        & ol {
            margin-top: 1em;
        }
    }

    ul:not(.list),
    ol {
        margin-left: 1em;

        li {
            margin-bottom: 0.75em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        & + * {
            margin-top: 2em;
        }
    }

    ul:not(.list) {
        list-style-type: disc;
    }

    ol:not(.numberedlist) {
        list-style-type: decimal;
    }

    a:not(.button):not(.link-external) {
        @include link($color-primary);

        &:hover {
            color: $color-light-blue;
        }
    }

    a[href*="streamfabriken"]:not(.lnk-external):not(.button),
    a[href*="media-server"]:not(.lnk-external):not(.button),
    a[href*="vevent"]:not(.lnk-external):not(.button),
    a[href$=".pdf"]:not(.lnk-external):not(.button),
    a[href$=".mp3"]:not(.lnk-external):not(.button) {
        position: relative;
        left: 1.5rem;
        margin-right: 1.5rem;

        &:before {
            position: absolute;
            top: 1px;
            left: -1.5rem;
            width: 20px;
            height: 20px;
            background: url(/Static/svg/icon-external-link.svg) 0 0 no-repeat;
            content: "";
        }
    }

    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    // img.Article-aside {
    //     width: auto;
    //     max-width: 100%;
    //     height: auto
    //     margin-top: 0.5em;
    //     margin-bottom: 0.5em;
    //     margin-left: 1.5em;
    //     float: right;
    // }

    .theme > * {
        margin-top: 2em;
        margin-bottom: 2em;

        @include media-breakpoint-up($bp-desktop) {
            margin-top: 2em;
            margin-bottom: 2em;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 2em;
            margin-bottom: 2em;
        }

        .block {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.margin-as-prose {
    .theme > * {
        margin-top: 2em;
        margin-bottom: 2em;

        @include media-breakpoint-up($bp-desktop) {
            margin-top: 2em;
            margin-bottom: 2em;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 2em;
            margin-bottom: 2em;
        }

        .block {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.Article-aside, // Article-aside comes from the old website via TinyMCE, keep until all usage has been removed
.article-aside {
    margin-bottom: 1em;

    @include media-breakpoint-up(md) {
        max-width: 22.75em;
        margin-right: -20%;
        margin-left: 2.5em;
        float: right;
    }

    &--block {
        position: relative;
        overflow: hidden;
        width: 100%;
        border-radius: 2px 4px 2.5em 4px;

        @include media-breakpoint-up(md) {
            max-width: 16em;
        }
    }
}
