.officepage {
    .infoblocks {
        .theme {
            margin-bottom: 3rem !important;
        }

        .container {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}

.infotextblock {
    .bg-primary,
    .bg-dark-blue {
        color: $color-white;
    }

    .bg-green,
    .bg-light-green,
    .bg-gray-100,
    .bg-lightest-blue,
    .bg-light-milky-blue,
    .bg-peach,
    .bg-white {
        color: $color-primary;
    }
}
