.stack {
    > * {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    &-md > * {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    &-lg > * {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    &-xl > * {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    &-none > * {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.stack-none-theme .theme > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.stack-self {
    margin-top: 1em;
    margin-bottom: 1em;

    &-md {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    &-lg {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    &-xl {
        margin-top: 3em;
        margin-bottom: 3em;
    }
}
