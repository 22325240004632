@each $color, $value in $text-colors {
    .text-#{$color} {
        color: $value;

        &:hover {
            color: $value;
        }
    }

    .fill-#{$color} {
        fill: $value;

        path,
        polygon {
            fill: $value;
        }
    }
}

.bg {
    @each $name, $color in $bg-colors {
        &-#{$name} {
            background-color: $color !important;
        }
    }
}

.fill-current {
    fill: currentColor;
    fill: currentColor;

    path,
    polygon {
        fill: currentColor;
    }
}

.stroke-current {
    stroke: currentColor;
}
