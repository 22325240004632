.widetextimageblock {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media-breakpoint-up($bp-desktop) {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    + .widetextimageblock {
    }

    .video-embed__button {
        position: absolute;
        top: 50%;
        left: 50%;
        width: clamp(3rem, 1rem + 5vw, 6rem);
        height: clamp(3rem, 1rem + 5vw, 6rem);
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        stroke: $color-green;
        transform: translate(-50%, -50%);
    }

    &--video {
        padding: 0;
    }

    .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
    }
}
