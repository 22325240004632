@mixin link(
    $color: currentColor,
    $hover-color: currentColor,
    $text-decoration: underline,
    $hover-text-decoration: underline
) {
    background-color: transparent; // Remove the gray background on active links in IE 10.
    color: $color;
    text-decoration: $text-decoration;

    &:hover {
        color: $hover-color;
    }

    &:active {
        color: $color;
    }
}

.link {
    @include link;
}

.link-arrow {
    $root: &;
    position: relative;
    display: inline-flex;
    align-items: center;
    color: currentColor;
    line-height: 1.25;

    &:hover {
        text-decoration: underline;
    }

    &::after {
        display: inline-flex;
        width: 1em;
        min-width: 20px;
        height: 1em;
        min-height: 20px;
        background-image: escape-svg($data-icon-arrow-right);
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        transform: translateX(0);
        transition: transform 0.2s ease-in-out;
        will-change: transform;
    }

    &:hover::after {
        text-decoration: underline;
        transform: translateX(0.25em);
    }

    &-large {
        &:after {
            min-width: 30px;
            min-height: 30px;
        }
    }

    &-reverse {
        &::before {
            display: inline-flex;
            width: 1em;
            min-width: 20px;
            height: 1em;
            min-height: 20px;
            background-image: escape-svg($data-icon-arrow-right);
            background-position: center;
            background-repeat: no-repeat;
            content: "";
            transform: translateX(0) rotate(180deg);
            transition: transform 0.2s ease-in-out;
            will-change: transform;
        }

        &:hover::before {
            text-decoration: underline;
            transform: translateX(-0.25em) rotate(180deg);
        }

        &::after {
            content: none;
        }
    }
}

.link-arrow-circle {
    $root: &;
    display: inline-flex;
    align-items: center;
    align-items: center;
    color: currentColor;
    font-size: $text-lg;
    line-height: $leading-tight;

    &:hover {
        text-decoration: underline;
    }

    &::before {
        display: inline-flex;
        width: 2em;
        min-width: 2em;
        height: 2em;
        align-items: center;
        margin-right: 0.5em;
        background-position: center;
        background-repeat: no-repeat;
        content: "";
    }

    &::before {
        background-image: url("../svg/icon-arrow-right-circle-darkest-blue-outlined.svg");
    }

    &:hover::before {
        background-image: url("../svg/icon-arrow-right-circle-darkest-blue-filled.svg");
    }

    &--medium-blue {
        color: $color-medium-blue;

        &::before {
            background-image: url("../svg/icon-arrow-right-circle-medium-blue-outlined.svg");
        }

        &:hover::before {
            background-image: url("../svg/icon-arrow-right-circle-medium-blue-filled.svg");
        }
    }
}

.contactinformationblock {
    a {
        color: $color-dark-blue;
        text-decoration: underline;
    }
}

.link-external {
    $root: &;
    position: relative;
    display: inline-flex;
    align-items: center;
    align-items: center;
    color: currentColor;

    &:hover {
        text-decoration: underline;
    }

    &::before {
        display: inline-flex;
        width: 1.25em;
        height: 1.25em;
        align-items: center;
        margin-right: 0.5em;
        background-image: escape-svg($data-icon-new-window-blue);
        background-position: center;
        background-repeat: no-repeat;
        content: "";
    }
}

// .link-icon {
//     $root: &;
//     position: relative;
//     display: inline-flex;
//     align-items: center;
//     align-items: center;
//     color: currentColor;

//     &:hover {
//         text-decoration: underline;
//     }

//     &::before {
//         display: inline-flex;
//         width: 2em;
//         height: 2em;
//         align-items: center;
//         margin-right: 0.5em;
//         background-position: center;
//         background-repeat: no-repeat;
//         content: "";
//     }

//     &::before {
//         background-image: escape-svg($data-icon-arrow-right-circle);
//     }

//     &[href$=".pdf"]::before,
//     &[href$=".docx"]::before,
//     &[href$=".doc"]::before {
//         background-image: escape-svg($data-icon-download);
//     }

//     &[target="_blank"]::before {
//         background-image: escape-svg($data-icon-new-window);
//     }

//     &[href^="mailto:"]::before {
//         background-image: escape-svg($data-icon-email);
//     }

//     &[href^="tel:"]::before {
//         background-image: escape-svg($data-icon-phone);
//     }
// }
