.object-fit {
    &-contain {
        object-fit: contain;
    }

    &-cover {
        object-fit: cover;
    }

    &-fill {
        object-fit: fill;
    }

    &-none {
        object-fit: none;
    }

    &-scale-down {
        object-fit: scale-down;
    }
}

.object {
    &-center#{&} {
        object-position: "center";
    }

    &-top-center#{&} {
        object-position: "top center";
    }

    &-top-right#{&} {
        object-position: "top right";
    }

    &-top-left#{&} {
        object-position: "top left";
    }

    &-center-right#{&} {
        object-position: "center right";
    }

    &-center-left#{&} {
        object-position: "center left";
    }

    &-center-bottom#{&} {
        object-position: "center bottom";
    }

    &-bottom-right#{&} {
        object-position: "bottom right";
    }

    &-bottom-left#{&} {
        object-position: "bottom left";
    }
}
