.aspect-ratio {
    $aspect-ratio: &;
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    padding: 0;

    @each $aspect-ratio in $aspect-ratios {
        $aspect-ratio-x: nth($aspect-ratio, 1);
        $aspect-ratio-y: nth($aspect-ratio, 2);

        &--#{$aspect-ratio-x}-#{$aspect-ratio-y} {
            &::before {
                padding-top: percentage($aspect-ratio-y / $aspect-ratio-x);
            }
        }
    }

    &--googlemaps {
        &::before {
            padding-top: percentage(3 / 4);

            @include media-breakpoint-up(md) {
                padding-top: percentage(2 / 6);
            }
        }
    }

    &--bg {
        &-white::before {
            background-color: $color-white;
        }

        &-gray::before {
            background-color: #f8f8f8;
        }
    }

    &::before {
        display: block;
        content: "";
    }

    &__item,
    iframe,
    video,
    noscript {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    // Used with intrinsic class
    &__center,
    &__center noscript {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }

    img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
    }

    &__intrinsic {
        width: auto;
        min-width: 1px;
        height: auto;
        min-height: 1px;
    }
}
