.landingpage {
    .page-content + .theme--white > * {
        margin-top: -1rem;

        @include media-breakpoint-up(xl) {
            margin-top: -2rem;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: -3rem;
        }
    }
}
