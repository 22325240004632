.hover\:underline:active,
.hover\:underline:hover {
    @at-root a#{&} {
        text-decoration: underline;
    }

    // @at-root .group#{&} a {
    //     text-decoration: underline;
    // }
}

.hover\:green:active,
.hover\:green:hover {
    color: $color-green;
}

.hover\:medium-blue:active,
.hover\:medium-blue:hover {
    color: $color-medium-blue;
}

.group {
    &:active,
    &:hover {
        .group-hover\:underline {
            text-decoration: underline;
        }
    }
}
