.border {
    border-width: $border-width;
}

.border-top {
    border-top-width: $border-width;
}

.border-right {
    border-right-width: $border-width;
}

.border-bottom {
    border-bottom-width: $border-width;
}

.border-left {
    border-left-width: $border-width;
}

.border-none {
    border: 0;
}

.border-dashed {
    border-style: dashed;
}

.border-dotted {
    border-style: dotted;
}

.border-first-none {
    &:first-child {
        border: none;
    }
}

.border-last-none {
    &:last-child {
        border: none;
    }
}

.border-absolute-left {
    position: relative;

    @include media-breakpoint-up(md) {
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -63px;
            width: 1px;
            height: 100%;
            background-color: $color-gray-400;
            content: "";
        }
    }
}

@each $color, $value in $border-colors {
    .border-#{$color} {
        border-color: $value;
    }
}

@each $amount, $value in $border-radius-amount {
    .border-radius-#{$amount} {
        border-radius: $value !important;
    }
}
