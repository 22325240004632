.teaser-count-down {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0.5rem;
    }
}
