.pageteaserblock {
    &.col-lg-6 {
        > div {
            @include media-breakpoint-up(xl) {
                width: 66%;
                margin: 0 auto;
            }
        }
    }
}
