[data-whatintent="mouse"],
[data-whatintent="touch"] {
    *:focus {
        outline: none;
    }
}

[data-whatintent="keyboard"] {
    & *:focus {
        outline: #222 solid 2px;
        outline-offset: 0.1ex;
    }
}
