$header__nav-height: 74px;
$header-languageheight: 150px;
$menu-selector: "m-menu";
$menu-item-border-color: rgba(255, 255, 255, 0.1);
$menu-item-hover-color: darken($color-dark-blue, 5%);

.mobile-menu-target {
    position: fixed;
    z-index: 3000;
    overflow: hidden;
    width: 100%;
    max-height: 0;
    background: $color-dark-blue;
    transition: max-height 0.5s;
    visibility: hidden;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    /* iOs hack */
    &[aria-hidden="true"] {
        > * {
            display: none;
        }
    }

    &[aria-hidden="false"] {
        overflow: visible;
        height: calc(100vh - (#{$header-languageheight}));
        max-height: none;
        transition: max-height 0.5s ease;
        transition-delay: 0s;
        visibility: visible;
    }

    .language-mobile {
        position: fixed;
        z-index: 9999;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 83px;
        border-top: 1px solid $menu-item-border-color;
        background: $color-primary;

        > div {
            top: 1.5em;
            left: 1em;
        }
    }
}

.#{$menu-selector} {
    $root: &;
    position: absolute;
    z-index: 999;
    top: -1px;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: calc(100vh - (#{$header__nav-height * 2.5}));
    max-height: none;
    flex-flow: column;
    padding: 0;
    border-top: 2px solid lighten($menu-item-border-color, 50%);
    margin-top: 1px;
    list-style: none;
    opacity: 1;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    transition: opacity 0.25s ease-in;
    visibility: visible;

    &[aria-hidden="false"] {
        display: flex;
        opacity: 1;
        visibility: visible;
    }

    ul {
        width: 100%;
        height: auto;
        min-height: 100%;
        padding-bottom: 5rem;
        padding-left: 0;
        list-style: none;
    }

    @include media-breakpoint-up(lg) {
        display: none !important;
    }

    &__item {
        display: flex;

        &--has-children {
            flex: 0 0 auto;
            flex-wrap: wrap;
        }
    }

    &__link {
        display: flex;
        width: 100%;
        padding: 1em;
        border-bottom-width: 1px;
        border-bottom-color: $menu-item-border-color;
        color: $color-white;
        font-size: 1rem;

        &:focus,
        &:hover,
        &:active {
            background-color: $menu-item-hover-color;
        }

        &--heading {
            flex: 1;
        }

        &--current {
            background-color: $menu-item-hover-color;
        }
    }

    &__link--back {
        font-size: 1.25em;
    }

    &__list {
        display: flex;
        overflow: hidden;
        height: 120vh;
        flex: 0 0 100%;
        flex-flow: column;
        background: $color-dark-blue;
        opacity: 0;
        transition: opacity 0.3s ease-in 0.1s;
        visibility: hidden;
    }

    &__toggle {
        position: relative;
        display: flex;
        width: 3.5em;
        flex: 0 0 3.5em;
        align-content: center;
        justify-content: center;
        border: 0;
        border-style: solid;
        border-color: $menu-item-border-color;
        border-bottom-width: 1px;
        border-left-width: 1px;
        margin-left: auto;
        background-color: transparent;
        cursor: pointer;

        &:focus,
        &:hover,
        &:active {
            background-color: $menu-item-hover-color;
        }

        + .#{$menu-selector}__list:first-of-type {
            position: absolute;
            z-index: 999;
            top: 0;
            overflow: visible;
            background: $color-dark-blue;
            opacity: 1;
        }

        &[aria-expanded="false"] + .#{$menu-selector}__list:first-of-type {
            transform: translate(100%);
            transition: transform 0.3s;
            visibility: hidden;
        }

        &[aria-expanded="true"] + .#{$menu-selector}__list:first-of-type {
            transform: translate(0);
            transition: transform 0.3s;
            visibility: visible;
        }

        &::after {
            position: absolute;
            z-index: 0;
            top: 50%;
            display: block;
            width: 2.25em;
            height: 2.25em;
            background-image: url(/Static/svg/icon-arrow-right-circle-white-outlined.svg);
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            transform: translateY(-50%);
        }

        &--back {
            border-style: solid;
            border-right-width: 1px;
            margin-right: auto;
            margin-left: unset;

            &:after {
                margin-right: 1em;
                margin-left: 1em;
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &-loader {
        display: flex;
        display: flex;
        height: calc(100vh - (#{$header__nav-height}));
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-top: 1px;
        background: #fff;

        &__inner {
            opacity: 0;

            &--show {
                animation: loader-fade-in 250ms 250ms ease-in forwards;
            }
        }

        &__circle {
            width: 60px;
            height: 60px;
            border: 3px solid #ccc;
            border-right-color: #4a4a4a;
            animation: circle-loader 1.5s infinite ease-out forwards;
            border-radius: 50%;
            transform-origin: center center;
        }
    }
}

@keyframes loader-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes circle-loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
