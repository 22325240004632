html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    border-width: 0;
    border-style: solid;
    border-color: $border-color;
}

html {
    height: 100%;
    box-sizing: border-box;
    background-color: $bg-body;
    font-family: $font-family-base;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    height: 100%;
    margin: 0;
    color: $color-primary;
    font-size: $text-base;
    line-height: $leading-normal;

    @include media-breakpoint-up($bp-desktop) {
        font-size: $text-d-base;
    }
}

hr {
    border-top: solid 1px $border-color;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mainbodycontentarea .block {
    margin-bottom: 4em;
}

.editMode [data-aos],
html.no-js [data-aos] {
    opacity: 1;
    transform: none;
}

[data-puzzel-chat] {
    z-index: 999;
}

@include media-breakpoint-down(sm) {
    #epi-quickNavigator {
        top: auto !important;
        right: -20px !important;
        bottom: -8px;
        transform: scale(0.75);
    }
}

#epi-quickNavigator a {
    display: flex;
    align-items: center;
}
