.underline {
    position: relative;
    padding-bottom: 0.75ex;
    margin-bottom: 0.75ex;

    &::before {
        position: absolute;
        bottom: 0;
        width: 70px;
        height: 2px;
        background-color: currentColor;
        content: "";
    }
}
