%standard-button {
    display: inline-flex;
    align-items: center;
    border-width: $btn-border-width;
    border-style: solid;
    border-color: currentColor;
    background-color: transparent;
    border-radius: 3em;
    color: currentColor;
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    font-size: inherit;
    font-weight: inherit;
    line-height: $leading-tight;
    text-align: center;
    text-decoration: none;
    user-select: none;
}

%standard-button-inverted {
    border-color: $color-white;
    background-color: $color-primary;
    color: $color-white;

    &:hover,
    &:focus {
        border-color: $color-primary;
        background-color: $color-white;
        color: $color-primary;
    }

    &::after {
        background-image: escape-svg($data-icon-arrow-right-white);
    }

    &:focus::after,
    &:hover::after {
        background-image: escape-svg($data-icon-arrow-right);
        transform: translateX(0.25em);
    }
}

.button {
    $root: &;
    @extend %standard-button;
    padding: 0.5em 1em;

    &:hover {
        border-color: $color-primary;
        background-color: $color-primary;
        color: $color-white;
    }

    &:focus,
    &.active {
        box-shadow: $btn-focus-box-shadow;
        outline: 0;
    }

    &::after {
        display: inline-flex;
        width: 1em;
        min-width: 1em;
        height: 1em;
        min-height: 1em;
        align-items: center;
        align-items: center;
        margin-left: 2.25em;
        background-image: escape-svg($data-icon-arrow-right);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1.25em;
        content: "";
        transform: translateX(0);
        transition: transform 0.2s ease-in-out;
        will-change: transform;
    }

    &:focus::after,
    &:hover::after {
        background-image: escape-svg($data-icon-arrow-right-white);
        transform: translateX(0.25em);
    }

    &--submenu {
        width: 100%;
        padding: 15px 30px;
        margin-bottom: 2.5rem;

        &::after {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &--inverted {
        border-color: $color-primary;
        background-color: $color-primary;
        color: $color-white;

        &:hover,
        &:focus {
            border-color: $color-primary;
            background-color: $color-white;
            color: $color-primary;
        }

        &::after {
            background-image: escape-svg($data-icon-arrow-right-white);
        }

        &:focus::after,
        &:hover::after {
            background-image: escape-svg($data-icon-arrow-right);
        }
    }

    &--no-arrow {
        &::after {
            display: none;
        }

        &:focus::after,
        &:hover::after {
            display: none;
        }
    }

    &--download {
        &:after {
            background-image: url(/Static/svg/icon-chevron-down-primary.svg);
        }

        &:focus::after,
        &:hover::after {
            background-image: url(/Static/svg/icon-chevron-down-primary-white.svg);
            transform: none;
        }
    }

    &--remove {
        height: 3rem;
        border-radius: 4px;

        &:after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxwYXRoIGQ9Ik0xOSA1TDUgMTlNNS4wMDAwMSA1TDE5IDE5IiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4gPC9zdmc+);
        }

        &:hover {
            background-color: transparent;
            color: currentColor;
        }

        &:hover::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxwYXRoIGQ9Ik0xOSA1TDUgMTlNNS4wMDAwMSA1TDE5IDE5IiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4gPC9zdmc+);
        }
    }

    &--green {
        border-color: $color-green;
        background-color: $color-green;
        color: $color-primary;

        &:hover,
        &:focus {
            border-color: $color-primary;
            background-color: $color-primary;
            color: $color-white;
        }

        &::after {
            background-image: escape-svg($data-icon-arrow-right);
        }

        &:focus::after,
        &:hover::after {
            background-image: escape-svg($data-icon-arrow-right-white);
        }
    }

    &-simple {
        @extend %standard-button;
        padding: 0.5em 2.25em;
    }

    &-reset {
        overflow: visible;
        width: auto;
        padding: 0;
        border: none;
        margin: 0;
        -webkit-appearance: none;
        background: transparent;
        color: inherit;
        font: inherit;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        line-height: normal;

        &:hover {
            border-color: transparent;
            background-color: transparent;
            color: inherit;
        }
    }

    &-googlemap {
        font-size: 16px;

        &:hover {
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22%23122f49%22%3E%3Cpath%20d%3D%22M11.284%205.589l.07.057%203%203%20.037.042.042.062.03.06.02.062.012.056L14.5%209l-.003.053-.014.075-.02.063-.052.093-.046.057-3.011%203.013a.5.5%200%2001-.765-.638l.057-.07L12.793%209.5H4a.5.5%200%2001-.09-.992L4%208.5h8.793l-2.147-2.146a.5.5%200%2001-.057-.638l.057-.07a.5.5%200%2001.562-.1l.076.043z%22%2F%3E%3C%2Fsvg%3E");
            }
        }

        &:after {
            margin-left: 0.5em;
        }
    }

    &--small {
        padding: 0.25em 0.75em;
        font-size: 0.75em;
    }

    &--office-search {
        padding: 0.66em 2em 0.66em 3em;

        svg {
            left: 1em;
        }

        &:after {
            display: none;
        }

        &:hover {
            &:after {
                display: none;
            }
        }
    }

    &--medium {
        font-size: 0.9em;
    }

    &--text-center {
        display: inline-block;
        text-align: center;
    }
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }
}
