.breadcrumb {
    $parent: &;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.75em 0;
    line-height: 1.1;

    &__item {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding: 0.25em 0;
        margin: 0 0.75em 0 0;

        &:last-child {
            margin-right: 0;
        }

        &:not(:last-of-type) {
            &:after {
                display: inline-flex;
                overflow: hidden;
                width: 0.35em;
                height: 0.35em;
                justify-content: center;
                padding: 0px;
                border-width: 0 1px 1px 0;
                border-style: solid;
                border-color: $color-primary;
                content: "";
                text-decoration: none !important;
                transform: rotate(-45deg);
            }
        }
    }

    &__link {
        border-bottom: transparent 1px solid;
        margin-right: 0.5em;
        color: currentColor;
        font-family: $font-family-base;
        font-weight: 400;
        word-spacing: 2px;

        &:hover {
            border-bottom: currentColor 1px solid;
            color: $color-medium-blue;
            text-decoration: none;
        }
    }
}
