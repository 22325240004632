$font-path: "/Static/fonts";

/* inter-300 - latin */
@font-face {
    font-display: swap;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    src: local(""),
        url("#{$font-path}/inter-v3-latin-300.woff2") format("woff2"),
        url("#{$font-path}/inter-v3-latin-300.woff") format("woff");
}

/* inter-regular - latin */
@font-face {
    font-display: swap;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: local(""),
        url("#{$font-path}/inter-v3-latin-regular.woff2") format("woff2"),
        url("#{$font-path}/inter-v3-latin-regular.woff") format("woff");
}

/* inter-600 - latin */
@font-face {
    font-display: swap;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: local(""),
        url("#{$font-path}/inter-v3-latin-600.woff2") format("woff2"),
        url("#{$font-path}/inter-v3-latin-600.woff") format("woff");
}
