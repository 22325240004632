header {
    box-shadow: 0 4px 20px 3px rgba(0, 0, 0, 0.04);
}

.main-header-row {
    &--sticky {
        position: fixed;
        z-index: 10000;
        top: 0;
        right: 0;
        left: 0;
        box-shadow: 0px 0px 10px #bfbfbf;
    }
}

.main-navigation__mega-menu {
    height: 100%;
}

.menu-bar {
    $menu: &;
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: stretch;
    padding: 0;
    margin: 0;
    list-style: none;

    & > li {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        padding: 0 0.75em;
        font-size: $text-sm;

        @include media-breakpoint-up(lg) {
            font-size: $text-base;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 1em;
        }

        @include media-breakpoint-up(xxl) {
            font-size: $text-lg;
        }
    }

    &__link {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        color: $color-dark-blue;

        &:focus {
            outline-offset: 1.5ex;
        }

        &--search:hover::before {
            display: none;
        }

        &[aria-expanded="true"]::after {
            left: 0;
            width: 100%;
            visibility: visible;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            display: block;
            width: 0;
            height: 2px;
            background: none repeat scroll 0 0 transparent;
            background: #34e0a1;
            content: "";
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &[aria-expanded="true"]::before {
            left: 0;
            width: 100%;
        }

        &--current {
            &::before {
                left: 0;
                width: 100%;
            }
        }

        @at-root .#{menu}__item:last-child #{&} {
            padding-right: 0;
        }
    }

    .mega-menu {
        position: absolute;
        z-index: 999;
        top: 100%;
        right: 0;
        width: 100%;
        min-width: 600px;
        padding: 1.5em;
        border-top: 1px solid $color-gray-100;
        background: $color-white;
        border-radius: 0 0 8px 8px;
        box-shadow: 0px 7px 11px -7px #9d9d9d;
        opacity: 0;
        visibility: hidden;

        &--sub-sections {
            width: 100%;

            h3 {
                margin-bottom: 0;
                font-size: 1.1em;
            }

            & > ul > li {
                width: 25%;
                padding: 1em;
            }
        }

        &--fade-in {
            animation-duration: 0.35s;
            animation-fill-mode: forwards;
            animation-name: megamenu-fadein;
        }

        &--fade-out {
            animation-duration: 0.25s;
            animation-fill-mode: forwards;
            animation-name: megamenu-fadeout;
        }

        @keyframes megamenu-fadein {
            0% {
                transform: translateY(-10px);
            }

            100% {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
            }
        }

        @keyframes megamenu-fadeout {
            0% {
                opacity: 1;
                visibility: visible;
            }
        }

        &--sub-sections .mega-menu__submenu {
            column-count: 1;
        }

        &__submenu {
            padding: 0em 0 0.5em;
            background: $color-white;
            column-count: 2;

            &-list {
                margin-top: 10px;
            }

            &__item {
                color: $color-dark-blue;

                & > a {
                    display: block;
                    padding: 0.5em 1em;

                    &:hover {
                        background: $color-gray-100;
                    }
                }

                &--top-level {
                    padding: 0 0.9em;
                    font-size: 1.1em;

                    &::after {
                        display: inline-flex;
                        width: 1em;
                        height: 1em;
                        flex: 0 0 1em;
                        margin-left: 0.35rem;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDA1NGEzIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1hcnJvdy1yaWdodCI+PHBhdGggZD0iTTUgMTJoMTRtLTctNyA3IDctNyA3Ii8+PC9zdmc+);
                        background-position: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        content: "";
                        line-height: inherit;
                        -webkit-transform: translateY(18%);
                        transform: translateY(18%);
                        transition: -webkit-transform 0.2s ease-in-out;
                        transition: transform 0.2s ease-in-out;
                        transition: transform 0.2s ease-in-out,
                            -webkit-transform 0.2s ease-in-out;
                        will-change: transform;
                    }
                }
            }

            &__item-container {
            }

            &__item-link {
                display: flex;
                overflow: hidden;
                width: 100%;
                align-items: center;
                padding: 0.5rem 1rem;
                transition: background ease 0.2s;

                @at-root .mega-menu__mega-menu--sub-sections
                        > ul
                        > li:nth-of-type(2)
                        #{&} {
                    /*padding: 0.5rem 1rem;*/
                }

                &--border {
                    padding: 10px;
                    border-bottom: 1px solid $color-gray-100;
                }

                &--text {
                    width: 100%;
                    margin-left: auto;
                }

                &:hover {
                    background: $color-gray-100;
                }
            }

            &__item-preamble {
                padding-bottom: 0.8rem;
                border-bottom: 1px solid $color-gray-100;
                margin-top: 0.5rem;
                color: $color-gray-800;
                font-size: 0.8rem;
            }
        }
    }
}
