.form-global-search {
    border-color: $color-primary;
}

.form {
    width: 100%;

    &__label,
    legend {
        display: block;
        margin-bottom: 0.25em;
        color: $color-primary;
        font-size: 1rem;
    }

    &__control {
        width: 100%;
        box-sizing: border-box;
        padding: 0.5em 1em;
        border: 1px solid $color-primary;
        border-radius: 4px;

        &::placeholder {
            font-size: 1rem;
        }
    }

    &__input-container {
        position: relative;
        display: block;
        padding-left: 1.5em;
        margin-bottom: 0;
        cursor: pointer;
        user-select: none;

        input {
            position: absolute;
            width: 0;
            height: 0;
            cursor: pointer;
            opacity: 0;

            &:checked ~ .form__checkmark {
                border-color: $color-primary;
                background-color: $color-primary;

                &--radio {
                    background-color: $color-white;
                }
            }

            &:checked ~ .form__checkmark:after {
                display: block;
                visibility: visible;
            }
        }

        &__link {
            text-decoration: underline;
        }
    }

    &__checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid $color-primary;
        background-color: inherit;

        &:after {
            position: absolute;
            top: 2px;
            left: 6px;
            width: 6px;
            height: 13px;
            border: solid $color-white;
            border-width: 0 2px 2px 0;
            content: " ";
            transform: rotate(45deg);
            visibility: hidden;
        }

        &--radio {
            width: 18px;
            height: 18px;
            border-radius: 50%;

            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
                width: 14px;
                height: 14px;
                border: none;
                background: $color-primary;
                border-radius: 50%;
                content: " ";
                transform: none;
                visibility: hidden;
            }
        }
    }

    select {
        @extend %standard-button;
        padding: 0.66em 2em 0.66em 1em;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjkgMTI5Ij48cGF0aCBkPSJNMTIxLjMgMzQuNmMtMS42LTEuNi00LjItMS42LTUuOCAwbC01MSA1MS4xLTUxLjEtNTEuMWMtMS42LTEuNi00LjItMS42LTUuOCAwLTEuNiAxLjYtMS42IDQuMiAwIDUuOGw1My45IDUzLjljLjguOCAxLjggMS4yIDIuOSAxLjIgMSAwIDIuMS0uNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIuMS01Ljh6Ii8+PC9zdmc+)
            0 0 no-repeat $color-white;
        background-position: 95% center;
        background-size: 15px;
        cursor: pointer;
        outline: none;

        &::-ms-expand {
            display: none;
        }

        option {
            text-align: left;
            text-align-last: center;
        }
    }

    &__search-autocomplete {
        position: absolute;
        z-index: 100;
        width: 100%;
        padding: 0;
        border: 1px solid $color-primary;
        margin: 0;
        margin-top: -2px;
        background: $color-white;
        border-radius: 0px 0px 4px 4px;
    }

    &__search-autocomplete li {
        z-index: 110;
        padding: 8px 12px;
        border-radius: 3px;
        list-style: none;
        transition: all 0.2s linear;
    }

    &__search-autocomplete li:hover {
        background: $color-gray-100;
        cursor: pointer;
    }

    &__search-submit {
        position: absolute;
        top: 12px;
        right: 15px;
        width: 20px;
        height: 20px;
        background: url(/Static/svg/icon-search.svg) 0 0 no-repeat;
        color: transparent;
        cursor: pointer;

        &:hover {
            color: transparent;
        }
    }

    div.mce_inline_error {
        padding: 0 !important;
        background-color: transparent !important;
        color: red !important;
        font-size: 0.875rem;
    }
}

.EPiServerForms .Form__Element.section {
    margin: 0 -15px 0 -15px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}
