.news-list-item {
    margin-bottom: 7rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 10rem;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 12rem;
    }

    &__image {
        border-radius: 5px;
        object-fit: cover;
    }

    &__content {
        position: absolute;
        z-index: 1;
        bottom: -5rem;
        left: 0;
        width: 100%;
        padding: 1em;
        background-color: $color-white;
        border-radius: 5px;
        box-shadow: 0px 5px 11px 1px rgb(0 0 0 / 15%);

        @include media-breakpoint-up(md) {
            bottom: -7rem;
            left: 15px;
        }

        @include media-breakpoint-up(xl) {
            bottom: -8.5rem;
        }
    }
}
