// Alias brekpoints for easier changes
$bp-desktop: xl;
$bp-desktop-fonts: xl;

// Color system

$color-current: currentColor;
$color-primary: #00354e; // (main

$color-dark-blue: #006390;
$color-medium-blue: #00b6ed;
$color-light-blue: #83d0f5;
$color-lightest-blue: #c9e8fb;
$color-milky-blue: #e4f3fd;
$color-light-milky-blue: #f5f9fa;
$color-green: #34e0a1;
$color-red: #ef0107;
$color-light-green: #caf7e7;
$color-peach: #f6b1a6;
$color-black: #000;
$color-white: #fff;

// Overlay colors
$overlay-color-dark: rgba(0, 0, 0, 0.2);
$overlay-color-light: rgba(255, 255, 255, 0.35);
$overlay-color-lighter: rgba(255, 255, 255, 0.7);
$overlay-color-blue: rgba(0, 53, 78, 0.5);

// Gray scale
$color-gray-100: #f0f2f3;
$color-gray-200: #e4e6e7;
$color-gray-300: #d2d5d8;
$color-gray-400: #a0a7ad;
$color-gray-500: #90979c;
$color-gray-600: #737b80;
$color-gray-700: #5e6265;
$color-gray-800: #464a4d;
$color-gray-900: #303234;

$color-lightest-gray: #f7f7f7;

$bg-body: $color-white;

$text-colors: (
    "current": $color-current,
    "primary": $color-primary,
    "white": $color-white,
    "dark-blue": $color-dark-blue,
    "medium-blue": $color-medium-blue,
    "light-blue": $color-light-blue,
    "lightest-blue": $color-lightest-blue,
    "green": $color-green,
    "red": $color-red,
    "peach": $color-peach,
    "gray-500": $color-gray-500,
    "gray-800": $color-gray-800,
    "black": $color-black,
);

$bg-colors: (
    "current": $color-current,
    "primary": $color-primary,
    "white": $color-white,
    "dark-blue": $color-dark-blue,
    "medium-blue": $color-medium-blue,
    "light-blue": $color-light-blue,
    "lightest-blue": $color-lightest-blue,
    "milky-blue": $color-milky-blue,
    "light-milky-blue": $color-light-milky-blue,
    "green": $color-green,
    "light-green": $color-light-green,
    "peach": $color-peach,
    "lightest-gray": $color-lightest-gray,
    "gray-100": $color-gray-100,
    "gray-200": $color-gray-200,
    "gray-300": $color-gray-300,
    "gray-400": $color-gray-400,
    "gray-500": $color-gray-500,
    "gray-600": $color-gray-600,
    "gray-700": $color-gray-700,
    "gray-800": $color-gray-800,
    "gray-900": $color-gray-900,
    "black": $color-black,
);

$themes: (
    "primary": $color-primary,
    "white": $color-white,
    "dark-blue": $color-dark-blue,
    "medium-blue": $color-medium-blue,
    "light-blue": $color-light-blue,
    "lightest-blue": $color-lightest-blue,
    "light-milky-blue": $color-light-milky-blue,
    "green": $color-green,
    "light-green": $color-light-green,
    "peach": $color-peach,
    "light-gray": $color-gray-100,
    "lightest-gray": $color-lightest-gray,
);

$button-colors: ();

$border-color: $color-gray-200;

$border-colors: (
    "primary": $border-color,
);

$border-radius-amount: (
    0: 0rem,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.25rem,
    6: 1.5rem,
    8: 2rem,
    10: 2.5rem,
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// -- Spacing
$layout-spacing-0: 0;
$layout-spacing-4: 0.25;
$layout-spacing-8: 0.5;
$layout-spacing-12: 0.75;
$layout-spacing-16: 1;
$layout-spacing-20: 1.25;
$layout-spacing-24: 1.5;
$layout-spacing-32: 2;
$layout-spacing-48: 3;
$layout-spacing-96: 6;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: $layout-spacing-0,
        1: (
            $spacer * $layout-spacing-4,
        ),
        2: (
            $spacer * $layout-spacing-8,
        ),
        3: (
            $spacer * $layout-spacing-12,
        ),
        4: (
            $spacer * $layout-spacing-16,
        ),
        5: (
            $spacer * $layout-spacing-20,
        ),
        6: (
            $spacer * $layout-spacing-24,
        ),
        7: (
            $spacer * $layout-spacing-32,
        ),
        8: (
            $spacer * $layout-spacing-48,
        ),
        9: (
            $spacer * $layout-spacing-96,
        ),
    ),
    $spacers
);

// This variable affects the `.h-*` and `.w-*` classes and sets fixed widths and heights.
// Usage .w-1 (width: .25rem), .h-5 (height: 1.25rem) etc

$sizes-em: ();
$sizes-em: map-merge(
    (
        0: 0,
        1: 0.25em,
        2: 0.5em,
        3: 0.75em,
        4: 1em,
        5: 1.25em,
        6: 1.5em,
        8: 2em,
        10: 2.5em,
        12: 3em,
        16: 4em,
        20: 5em,
        24: 6em,
        32: 8em,
        40: 10em,
        48: 12em,
        56: 14em,
        64: 16em,
        auto: auto,
    ),
    $sizes-em
);

// Setting the maximum width of an element

$max-widths: (
    sm: 30rem,
    lg: 40rem,
);

// Body text weights
$font-light: 300;
$font-normal: 400;
$font-bold: 600;

// h1,h2, h3, h4, h5
$font-weight-heading: $font-bold;

$font-weights: (
    light: $font-light,
    normal: $font-normal,
    bold: $font-bold,
);

$font-family-base: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";

// line heights
$leading-none: 1;
$leading-tight: 1.25;
$leading-snug: 1.3125;
$leading-normal: 1.5;
$leading-relaxed: 1.625;
$leading-extra: 1.7;

$leadings: (
    "none": $leading-none,
    "tight": $leading-tight,
    "snug": $leading-snug,
    "normal": $leading-normal,
    "relaxed": $leading-relaxed,
    "extra": $leading-extra,
);

$headings-margin-bottom: $spacer / 2;
$headings-line-height: 1.2 !default;

// Mobile font sizes

$text-xs: rem(12px);
$text-sm: rem(14px);
$text-base: rem(16px);
$text-lg: rem(18px);
$text-xl: rem(20px); // ingress
$text-2xl: rem(22px); // Heading 3
$text-3xl: rem(28px); // Heading 2
$text-4xl: rem(32px); // Heading 1
$text-5xl: rem(40px); // Display
$text-hero: rem(30px);

// Desktop font sizes

$text-d-xs: rem(14px);
$text-d-sm: rem(16px);
$text-d-base: rem(18px);
$text-d-lg: rem(20px);
$text-d-xl: rem(22px); // ingress
$text-d-2xl: rem(24px); // Heading 3
$text-d-3xl: rem(32px); // Heading 2
$text-d-4xl: rem(48px); // Heading 1
$text-d-5xl: rem(64px); // Display
$text-d-hero: rem(48px);

// citat 40px

$font-sizes-map: (
    null: (
        "xs": (
            "size": $text-xs,
            "lead": $leading-normal,
        ),
        "sm": (
            "size": $text-sm,
            "lead": $leading-normal,
        ),
        "base": (
            "size": $text-base,
            "lead": $leading-normal,
        ),
        "lg": (
            "size": $text-lg,
            "lead": $leading-normal,
        ),
        "xl": (
            "size": $text-xl,
            "lead": $leading-normal,
        ),
        "2xl": (
            "size": $text-2xl,
            "lead": $leading-snug,
        ),
        "3xl": (
            "size": $text-3xl,
            "lead": $leading-snug,
        ),
        "4xl": (
            "size": $text-4xl,
            "lead": $leading-snug,
        ),
        "5xl": (
            "size": $text-5xl,
            "lead": $leading-snug,
        ),
    ),
    "#{$bp-desktop-fonts}": (
        "xs": (
            "size": $text-d-xs,
            "lead": $leading-normal,
        ),
        "sm": (
            "size": $text-d-sm,
            "lead": $leading-normal,
        ),
        "base": (
            "size": $text-d-base,
            "lead": $leading-normal,
        ),
        "lg": (
            "size": $text-d-lg,
            "lead": $leading-normal,
        ),
        "xl": (
            "size": $text-d-xl,
            "lead": $leading-normal,
        ),
        "2xl": (
            "size": $text-d-2xl,
            "lead": $leading-snug,
        ),
        "3xl": (
            "size": $text-d-3xl,
            "lead": $leading-snug,
        ),
        "4xl": (
            "size": $text-d-4xl,
            "lead": $leading-snug,
        ),
        "5xl": (
            "size": $text-d-5xl,
            "lead": $leading-snug,
        ),
    ),
);

// Links
//
// Style anchor elements.

$link-color: $color-primary;
$link-hover-color: $color-dark-blue;
$link-hover-decoration: none;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px;
$border-color: $color-gray-300;

$border-radius: rem(6px);
$border-radius-lg: 0.5rem;
$border-radius-sm: 0.25rem;

$rounded-pill: 50rem;

$box-shadow: 0 4px 20px 3px rgba(0, 0, 0, 0.04);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 1024px,
    xl: 1200px,
    xxl: 1400px,
);

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 420px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);

// @include _assert-ascending($container-max-widths, "$container-max-widths");

$aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$aspect-ratios: join(
    (
        (25 9),
        (21 9),
        (16 9),
        (4 3),
        (6 2),
        (3 2),
        (2 1),
        (1 1),
        (4 5),
        (1 2),
        (2 3),
        (4 5),
        (3 4),
        (3 5)
    ),
    $aspect-ratios
);

// SVG as data image
// encodeURIComponent()
// http://pressbin.com/tools/urlencode_urldecode/

$data-icon-chevron-down: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E");
$data-icon-arrow-right: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22%23122f49%22%3E%3Cpath%20d%3D%22M11.284%205.589l.07.057%203%203%20.037.042.042.062.03.06.02.062.012.056L14.5%209l-.003.053-.014.075-.02.063-.052.093-.046.057-3.011%203.013a.5.5%200%2001-.765-.638l.057-.07L12.793%209.5H4a.5.5%200%2001-.09-.992L4%208.5h8.793l-2.147-2.146a.5.5%200%2001-.057-.638l.057-.07a.5.5%200%2001.562-.1l.076.043z%22%2F%3E%3C%2Fsvg%3E");
$data-icon-arrow-right-white: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M11.284%205.589l.07.057%203%203%20.037.042.042.062.03.06.02.062.012.056L14.5%209l-.003.053-.014.075-.02.063-.052.093-.046.057-3.011%203.013a.5.5%200%2001-.765-.638l.057-.07L12.793%209.5H4a.5.5%200%2001-.09-.992L4%208.5h8.793l-2.147-2.146a.5.5%200%2001-.057-.638l.057-.07a.5.5%200%2001.562-.1l.076.043z%22%2F%3E%3C%2Fsvg%3E");
$data-icon-download: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22%23122f49%22%3E%3Cpath%20id%3D%22a%22%20d%3D%22M2%2017h14v-2H2m14-9h-4V0H6v6H2l7%207%207-7z%22%2F%3E%3C%2Fsvg%3E");
$data-icon-new-window: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22%23122f49%22%3E%3Cpath%20id%3D%22a%22%20d%3D%22M11%200v2h3.59l-9.83%209.83%201.41%201.41L16%203.41V7h2V0m-2%2016H2V2h7V0H2a2%202%200%2000-2%202v14a2%202%200%20002%202h14a2%202%200%20002-2V9h-2v7z%22%2F%3E%3C%2Fsvg%3E");
$data-icon-new-window-blue: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDBiNmVkIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9Imljb24tZXh0ZXJuYWwtbGluayI+DQogIDxwYXRoIGQ9Ik0xOCAxM3Y2YTIgMiAwIDAxLTIgMkg1YTIgMiAwIDAxLTItMlY4YTIgMiAwIDAxMi0yaDZNMTUgM2g2djZNMTAgMTRMMjEgMyIvPg0KPC9zdmc+DQo=");
$data-icon-email: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23122f49%22%3E%3Cpath%20d%3D%22M20%208l-8%205-8-5V6l8%205%208-5m0-2H4c-1.11%200-2%20.89-2%202v12a2%202%200%20002%202h16a2%202%200%20002-2V6a2%202%200%2000-2-2z%22%2F%3E%3C%2Fsvg%3E");
$data-icon-phone: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23122f49%22%3E%3Cpath%20d%3D%22M6.62%2010.79c1.44%202.83%203.76%205.15%206.59%206.59l2.2-2.2c.28-.28.67-.36%201.02-.25%201.12.37%202.32.57%203.57.57a1%201%200%20011%201V20a1%201%200%2001-1%201A17%2017%200%20013%204a1%201%200%20011-1h3.5a1%201%200%20011%201c0%201.25.2%202.45.57%203.57.11.35.03.74-.25%201.02l-2.2%202.2z%22%2F%3E%3C%2Fsvg%3E");

// Mobile menu - toggle icons
$icon-plus: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#081d2f" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10"/><path d="M12 8v8M8 12h8"/></svg>');
$icon-minus: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#207ab4" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus-circle" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10"/><path d="M8 12h8"/></svg>');

$data-icon-arrow-right-circle: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%20%20%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2210%22%2F%3E%0A%20%20%3Cpath%20d%3D%22M12%2016l4-4-4-4M8%2012h8%22%2F%3E%0A%3C%2Fsvg%3E%0A");
$data-icon-arrow-right-circle: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#00354e" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" stroke="#00354e" /><path d="M12 16l4-4-4-4M8 12h8"/></svg>');
