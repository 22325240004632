.logo {
    $root: &;
    width: 100%;
    max-width: rem(270px);
    height: 100%;
    max-height: rem(56px);

    &--header {
        max-width: 55%;
        margin: 1.25rem auto 1.25rem 0;

        @include media-breakpoint-up(lg) {
            max-width: 10rem;
            margin: 1.75rem auto 1.75rem 0;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 12.5rem;
            margin: 2.25rem auto 2.25rem 0;
        }
    }

    &--header-100 {
        max-width: 75%;
        margin: 1.55rem auto 1.55rem 0;

        @include media-breakpoint-up(xxl) {
            max-width: 100%;
            margin: 2.25rem auto 2.25rem 0;
        }
    }

    &--footer {
        max-width: 11.875rem;
    }

    &__icon {
        fill: $color-green;
    }

    &__text {
        fill: $color-dark-blue;
    }

    &--inverted {
        #{$root} {
            &__text {
                fill: $color-white;
            }
        }
    }
}
