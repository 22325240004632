.numbered-puff {
    background-color: transparent;

    &--has-lines {
        &:not(:nth-last-child(1)) {
            .numbered-puff__number::before {
                position: absolute;
                top: 50%;
                right: -350%;
                display: none;
                width: 350%;
                height: 4px;
                background-color: $color-primary;
                content: "";

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }

        &:nth-child(3n + 3) {
            .numbered-puff__number::before {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }

        &:nth-child(4n + 4) {
            .numbered-puff__number::before {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }
    }

    &.theme {
        &--light-green,
        &--light-gray,
        &--lightest-blue,
        &--light-milky-blue,
        &--peach,
        &--white {
            .numbered-puff__number {
                color: $color-primary;
            }
        }

        @each $name, $color in $themes {
            &--#{$name} {
                .numbered-puff__number::before,
                .numbered-puff__number::after {
                    background-color: $color;
                }
            }
        }
    }

    &__number {
        position: relative;
        z-index: 2;
        display: flex;
        min-width: 42px;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1 / 1;
        color: $color-white;
        font-weight: $font-bold;

        @include media-breakpoint-up(lg) {
            min-width: 63px;
        }

        &:after {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            min-width: 120%;
            min-height: 120%;
            background-color: $color-primary;
            border-radius: 100%;
            content: "";
            transform: translate(-50%, -50%);
        }
    }
}
