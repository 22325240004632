header {
    box-shadow: 0 4px 20px 3px rgba(0, 0, 0, 0.04);
}

.menu {
    $menu: &;
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: stretch;
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        position: relative;
        padding: 0 0.75em;
        font-size: $text-sm;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            font-size: $text-base;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 1em;
        }

        @include media-breakpoint-up(xxl) {
            font-size: $text-lg;
        }
    }

    &__link {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;

        &:before {
            position: absolute;
            bottom: -5px;
            left: 50%;
            display: block;
            width: 0;
            height: 2px;
            background: none repeat scroll 0 0 transparent;
            background: $color-green;
            content: "";
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &:hover {
            &:before {
                left: 0;
                width: 100%;
            }
        }

        &--no-children {
            display: block;
        }

        &--current {
            &:before {
                left: 0;
                width: 100%;
            }
        }

        @at-root .#{menu}__item:last-child #{&} {
            padding-right: 0;
        }
    }

    &__submenucontainer {
        position: absolute;
        opacity: 0;
        transform: translateY(5px);
        transition: transform 0.2s ease-in, opacity 0.2s ease-in,
            visibility 0.2s ease-in;
        visibility: hidden;

        @at-root .#{menu}__item:hover #{&},
            .#{menu}__item:focus #{&} {
            z-index: 999;
            opacity: 1;
            -ms-transform: translateY(0);
            transform: translateY(0);
            transition: transform 0.2s ease-in, opacity 0.2s ease-in,
                visibility 0.2s ease-in;
            visibility: visible;
        }
    }

    &__submenu {
        padding: 1.5em 0;
        margin-top: 2.5em;
        background: $color-white;
        border-radius: 0 0 25px 0;

        &__item {
            min-width: 320px;
            padding: 0.5em 1em;

            &:hover,
            &--current {
                border-left: 5px solid $color-dark-blue;
                background: $color-milky-blue;

                a {
                    margin-left: -5px;
                }
            }
        }
    }
}
