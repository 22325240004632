@mixin font($size, $lead) {
    font-size: $size;
    line-height: $lead;
}

@mixin font-sizes($f-map) {
    @each $device, $devices-map in $f-map {
        @each $bp, $value in $devices-map {
            $size: map-get($value, "size");
            $lead: map-get($value, "lead");

            @if ($device == null) {
                .text-#{$bp} {
                    @include font($size, $lead);
                }
            } @else {
                @include media-breakpoint-up($device) {
                    .text-#{$bp} {
                        @include font($size, $lead);
                    }
                }
            }
        }
    }
}

@mixin owl($measure: small) {
    & > * + * {
        margin-top: $measure;
    }
}
