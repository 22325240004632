@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 0.75rem !default;
$hamburger-padding-y: 1.125rem !default;
$hamburger-layer-width: 1.875rem !default;
$hamburger-layer-height: 0.1975rem !default;
$hamburger-layer-spacing: 0.25rem !default;
$hamburger-layer-color: $color-dark-blue !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;

// Hamburger
// ==================================================
.c-hamburger {
    display: inline-flex;
    overflow: visible;
    width: auto;
    width: 3em;
    height: 3em;
    flex-shrink: 0;
    align-items: center;
    padding: 0.75em;
    border: 0;
    margin: 0;
    background-color: transparent;
    border-radius: 50%;
    color: inherit;
    cursor: pointer;
    // Normalize (<button>)
    font: inherit;
    text-transform: none;
    transition-duration: $hamburger-hover-transition-duration;
    transition-property: opacity, filter;
    transition-timing-function: $hamburger-hover-transition-timing-function;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        } @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &-box {
        position: relative;
        display: inline-block;
        width: 100%;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    }

    span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background: $hamburger-layer-color;
        border-radius: 9px;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0;

            @at-root [aria-expanded="true"]#{&} {
                top: ($hamburger-layer-spacing + $hamburger-layer-height) * 1;
                left: 50%;
                width: 0%;
            }
        }

        &:nth-child(2) {
            @at-root [aria-expanded="true"]#{&} {
                transform: rotate(45deg);
            }
        }

        &:nth-child(3) {
            @at-root [aria-expanded="true"]#{&} {
                transform: rotate(-45deg);
            }
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * 1;
        }

        &:nth-child(4) {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * 2;

            @at-root [aria-expanded="true"]#{&} {
                top: ($hamburger-layer-spacing + $hamburger-layer-height) * 1;
                left: 50%;
                width: 0%;
            }
        }
    }
}
