.googlemaps {
    .cluster {
        width: 32px !important;
        height: 32px !important;

        > div {
            width: 32px !important;
            height: 32px !important;
            color: #fff !important;
            line-height: 32px !important;
        }
    }

    &__infowindow {
    }

    &__close {
        span {
            position: absolute;
            top: 26px;
            left: 0;
            width: 16px;
            height: 2px;
            background: $color-black;

            &:first-child {
                transform: rotate(45deg);
            }

            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }

    .gm-style-iw-d {
        overflow: none !important;
    }
    /*style the box*/
    .gm-style .gm-style-iw-c {
        width: 300px;
        padding: 20px 16px;
        border-radius: 0;
    }
}
