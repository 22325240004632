.vacancylandingpage {
    &__filters {
        @media only screen and (max-width: 1024px) {
            li,
            select {
                width: 100%;
            }
        }
    }

    &__reachmelinks {
        justify-content: end;
        margin-left: auto;

        a {
            display: flex;
            align-content: center;
            justify-content: space-between;
            margin: 0rem 4rem 1rem 0rem;
        }

        @media only screen and (max-width: 1024px) {
            padding: 0rem 1rem;
            margin-top: 1rem;
            margin-left: 0;
        }
    }

    &__tableParent {
        @media only screen and (max-width: 780px) {
            width: 100% !important;
            overflow-x: scroll;
        }

        table {
            th {
                cursor: pointer;
            }

            td {
                font-size: 1rem !important;
            }
        }

        table thead tr th {
            color: #006390 !important;
            font-size: 22px;
        }

        table td {
            a {
                display: block;
                color: #00354e;
                text-decoration: underline;
            }

            :hover {
                color: #83d0f5;
            }
        }

        .table:nth-child(even) {
            background-color: #f7fdfe;
        }
    }
}

.vacancypage {
    &__contactpersonsection {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        margin-bottom: 2rem;

        .name {
            margin-top: 1rem;
            font-weight: 600;
        }

        .info {
            margin: 0.2rem 0rem 0.2rem 0rem;
        }

        .link {
            margin: 0.2rem 0rem 0.2rem 0rem;
            text-decoration: underline;
        }
    }

    &__recruitmentbox {
        min-width: 18rem;
        height: fit-content;
        padding: 26px 25px 26px 25px;
        background-color: #0063900f;
        border-radius: 8px;

        h2 {
            align-self: baseline;
            margin-bottom: 1rem;
            font-weight: 600;
        }

        div {
            display: flex;
            flex-wrap: wrap;
            font-size: 1rem;
            word-break: break-all;
        }

        &__addetails {
            display: flex;
            align-self: baseline;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &__contactdetails {
            width: 100%;
            margin: 0.1rem 0rem 0.25rem 2.6rem;
        }

        svg {
            width: 24px;
            min-width: 24px;
            margin-right: 1rem;
        }

        .phone {
            font-size: 1rem;
            text-decoration: underline;
            word-break: break-all;
        }
    }

    &__sharelinks {
        padding: 2rem 0 0 2rem;

        h3 {
            margin-bottom: 1rem;
            font-weight: 600;
        }

        svg {
            fill: #006490;
            font-size: 1.4rem;
        }
    }

    &__applybutton--short {
        width: 16rem;
    }

    &__applybutton--long {
        width: 100%;
    }

    &__applybutton {
        display: block;
        padding: 0.5em 1em;
        border-width: 1px;
        border-style: solid;
        border-color: currentColor;
        margin-top: 2rem;
        background-color: #006490;
        border-radius: 3em;
        color: #ffffff;
        cursor: pointer;
        font-family: inherit;
        font-size: 1rem;
        font-size: inherit;
        font-weight: inherit;
        line-height: 1.25;
        text-align: center;
        text-decoration: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
