// Sets both Width and height in em s-#{size}
@each $size, $length in $sizes-em {
    .s-#{$size} {
        width: $length !important;
        height: $length !important;
    }
}

// Width and height em w-#{size}, h-#{size}

@each $prop, $abbrev in (width: w, height: h) {
    @each $size, $length in $sizes-em {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
    }

    @each $size, $length in $sizes-em {
        .min-#{$abbrev}-#{$size} {
            min-#{$prop}: $length !important;
        }
    }
}
