.cookieconcent-placeholder {
    position: relative;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;

    .left-content & {
        position: absolute;

        .cookieconcent-youtube-placeholder__text {
            width: 80%;
            font-size: 14px;
            transform: translate(-50%, -50%);
        }
    }

    &::before {
        display: block;
        padding-top: 56.25%;
        content: "";
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        content: "";
    }

    &__image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border: 0;
        object-fit: cover;
    }

    &__text {
        position: absolute;
        z-index: 9999;
        top: 50%;
        left: 50%;
        width: 80%;
        padding: 1em;
        background-color: #fff;
        color: #000;
        font-size: 14px;
        text-align: center;
        transform: translate(-50%, -54%);

        @include media-breakpoint-up(md) {
            width: 75%;
            max-height: 80%;
            padding: 1em;
            font-size: 18px;
        }
    }
}
