.toolblock {
    &__container {
        display: flex;
    }

    p {
        padding-top: 0.25em;
    }

    &.col-lg-3,
    &.col-lg-4 {
        .toolblock__container {
            display: block;
        }

        .toolblock__icon {
            display: flex;
            justify-content: center;
            margin-bottom: 1em;
        }

        p {
            padding-top: 0;
            text-align: center;
        }
    }
}
