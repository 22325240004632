// This is needed to vertically center the content in IE11
.heroblock {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
}

.hero {
    $hero: &;
    position: relative;
    display: flex;
    overflow: hidden;
    height: 60vh;
    max-height: 600px;
    align-items: center;
    justify-content: center;
    color: $color-white;
    font-size: 16px;

    @include media-breakpoint-up(sm) {
        height: 65vh;
    }

    @include media-breakpoint-up(lg) {
        height: 85vh;
    }

    &--rounded {
        border-bottom-right-radius: calc(
            2.5rem + (((100vw - 20rem) / 50)) * 2.5
        );
    }

    &--content-height {
        height: auto;
        max-height: none;
    }

    &__media {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        video {
            top: 0;
            height: 100%;
        }

        &:after {
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            opacity: 1;
            pointer-events: none;
            //     @at-root #{$hero}--bg-black#{$hero} & {
            //         background-color: rgba($color-black-real, 1);
            //     }
            //     // Adds a default opacity if background color is set
            //     // to black and no opacity has been selected
            //     @at-root #{$hero}--bg-black#{$hero}:not([class*=" hero--opacity-"])
            //             & {
            //         opacity: 0.35;
            //     }
            //     @for $i from 1 through 10 {
            //         $opacity: $i * 0.1;
            //         @at-root #{$hero}--opacity-#{$i} & {
            //             opacity: $opacity;
            //         }
            //     }
            @at-root #{$hero}--overlay-dark#{$hero} & {
                background-color: $overlay-color-dark;
            }

            @at-root #{$hero}--overlay-light#{$hero} & {
                background-color: $overlay-color-light;
            }

            @at-root #{$hero}--overlay-lighter#{$hero} & {
                background-color: $overlay-color-lighter;
            }

            @at-root #{$hero}--overlay-blue#{$hero} & {
                background-color: $overlay-color-blue;
            }
        }
    }

    .object-fit {
        position: relative;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content-container {
        z-index: 30;

        @at-root #{$hero}--overlay-lighter#{$hero} & {
            color: $color-primary;
        }
    }

    &__content {
        padding-top: 3em;
        padding-bottom: 3em;
    }

    &__header {
        font-size: $text-hero;

        @include media-breakpoint-up($bp-desktop-fonts) {
            font-size: $text-d-hero;
        }
    }

    .hero__toggle-play {
        position: absolute;
        z-index: 1;
        top: 1em;
        right: 1em;
        display: flex;
        /* width: 1.5em; */
        /* height: 1.5em; */
        display: flex;
        align-items: center;
        padding: 0.5ex 1.25ex;
        border: 0;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-size: 1em;
        font-size: 0.75em;
        font-size: 1.35ex;
        text-transform: uppercase;

        .hero__toggle-play__state {
            position: relative;
            display: inline-block;
            width: 1.75em;
            height: 1.75em;
            margin-right: 1ex;

            &::before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='white' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='icon-play-circle'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cpath d='M10 8l6 4-6 4V8z'/%3E%3C/svg%3E");
                background-position: center;
                background-size: 100%;
                content: "";
            }
        }

        &[aria-pressed="true"] .hero__toggle-play__state::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='icon-circle-pause'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='10' y1='15' x2='10' y2='9'%3E%3C/line%3E%3Cline x1='14' y1='15' x2='14' y2='9'%3E%3C/line%3E%3C/svg%3E%0A");
        }
    }

    .hero__object-fit {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: none;
        height: 100%;
        font-family: "object-fit: cover; object-position: top center;";
        object-fit: cover;
        object-position: top center;
        transform: translate(-50%, -50%);
        // Target ie10 and ie11
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            @at-root #{selector-unify(&, video)} {
                height: auto;
            }
        }

        @supports (-ms-ime-align: auto) {
            @at-root #{selector-unify(&, video)} {
                height: auto;
            }
        }

        @at-root .hero--center#{&} {
            font-family: "object-fit: cover; object-position: center;";
            object-position: center;
        }
    }
}
