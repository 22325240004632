// Utilities for setting the maximum width of an element

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        .max-w#{$infix} {
            max-width: map-get($max-widths, $breakpoint);
        }
    }
}
