.chatbot {
    position: fixed;
    z-index: 9999999999;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: auto;
    max-height: 4rem;
    flex-direction: column;
    align-items: end;
    transition: max-height 0.25s ease-in-out;

    &--expanded {
        top: 0;
        height: 100%;
        max-height: 100vh;
    }

    @include media-breakpoint-up(md) {
        top: auto;
        bottom: 5em;
        left: auto;
        min-width: 185px;
        max-width: 330px;
        height: auto;
        margin-right: 2em;
    }

    &__iframe-wrapper {
        overflow: hidden;
        width: 100%;
        height: calc(100% - 5em);
        max-height: 100vh;
        background-color: $color-gray-200;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%),
            0 4px 6px -2px rgb(0 0 0 / 5%);
        transition: height 0.25s ease-in-out, max-height 0.25s ease-in-out;

        @include media-breakpoint-up(md) {
            height: 550px;
            border-radius: 0.5em;
            transition: height 0.25s ease-in-out, max-height 0.25s ease-in-out;
        }

        &[aria-hidden="true"] {
            height: 0;
            max-height: 0;
        }
    }

    &__button {
        position: absolute;
        right: 5em;
        bottom: 1em;
        width: 3.5em;
        height: 3.5em;
        padding: 0.75em;
        background-color: $color-dark-blue;
        border-radius: 3em;
        box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2),
            0 0 5px -1px rgba(0, 0, 0, 0.2);
        transition: opacity 0.25s ease-in-out;

        @include media-breakpoint-up(md) {
            position: static;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    &__pin {
        position: absolute;
        right: 5rem;
        bottom: 5rem;
        padding: 1rem;
        background-color: $color-dark-blue;
        border-radius: 0.5rem;
        color: $color-white;
        opacity: 1;
        transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
        visibility: visible;

        &::after {
            position: absolute;
            z-index: -1;
            right: 1rem;
            bottom: -2px;
            width: 22px;
            height: 22px;
            background-color: $color-dark-blue;
            border-radius: 3px;
            content: "";
            transform: rotate(45deg);
        }

        @include media-breakpoint-up(md) {
            bottom: 0;

            &::after {
                top: 50%;
                right: -2px;
                bottom: auto;
            }
        }

        &[aria-hidden="true"] {
            opacity: 0;
            visibility: hidden;
        }
    }
}
