.theme {
    width: 100%;
    $theme: &;

    @at-root [class*="theme"]:not([class*="theme--"])
            + [class*="theme"]:not([class*="theme--"]) {
        border-top: none;
    }

    &:not([class*="theme--"]) + .theme--white {
        border-top: none;
    }

    &.theme--white:not([class*="theme--"])
        + .theme:not([class*="theme--white"]) {
        border-top: none;

        > * {
            margin-top: 0;
        }
    }

    > * {
        margin-top: 3em;
        margin-bottom: 3em;

        @include media-breakpoint-up($bp-desktop) {
            margin-top: 4em;
            margin-bottom: 4em;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 5em;
            margin-bottom: 5em;
        }
    }

    @each $name, $color in $themes {
        &--#{$name} {
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            background-color: #{$color};

            + .theme--#{$name} {
                border-top: none;

                > * {
                    margin-top: 0;
                }
            }
        }
    }

    &--primary {
        color: $color-white;

        .button {
            border-color: $color-white;
            background-color: transparent;
            color: $color-white;

            &:hover {
                background-color: $color-white;
                color: $color-primary;
            }

            &:focus {
                background-color: $color-white;
                color: lighten($color-primary, 20%);
            }

            &::after {
                background-image: escape-svg($data-icon-arrow-right-white);
            }

            &:hover::after {
                background-image: escape-svg($data-icon-arrow-right);
                transform: translateX(0.25em);
            }
        }
    }

    &--dark-blue {
        color: $color-white;

        .button:not(.numberedpufflistblock__button) {
            border-color: $color-white;
            background-color: transparent;
            color: $color-white;

            &:hover {
                background-color: $color-white;
                color: $color-primary;
            }

            &:focus {
                background-color: $color-white;
                color: lighten($color-primary, 20%);
            }

            &::after {
                background-image: escape-svg($data-icon-arrow-right-white);
            }

            &:hover::after {
                background-image: escape-svg($data-icon-arrow-right);
                transform: translateX(0.25em);
            }
        }
    }

    &--peach,
    &--green,
    &--light-green,
    &--light-blue {
        .icon-cogwheel {
            fill: $color-primary;

            path,
            polygon {
                fill: $color-primary;
            }
        }
    }

    &--no-border-xxl {
        @include media-breakpoint-up(xxl) {
            border-top: none;
            border-bottom: none;
        }
    }

    &--rounded {
        border-bottom-right-radius: calc(
            2.5rem + (((100vw - 20rem) / 50)) * 2.5
        );
    }
}
