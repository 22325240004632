.c-dropdown {
    &--fit-content {
        width: fit-content;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: table;
        }
    }

    &__button-container {
        display: inline-block;
        padding: 0.175em 2em 0.4em 0.5em;

        &--outline {
            position: relative;
            z-index: 2;
            padding: 0.58rem 2em 0.6rem 1.125rem;
            border: 1px solid $color-primary;
            border-radius: 3em;
        }

        &--wide {
            padding: 0.58rem 5em 0.6rem 1.125rem;
        }
    }

    &__button {
        .language-mobile & {
            color: $color-white;
        }

        &:after {
            position: absolute;
            top: -5px;
            right: -20px;
            width: 12px;
            height: 100%;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjkgMTI5Ij48cGF0aCBkPSJNMTIxLjMgMzQuNmMtMS42LTEuNi00LjItMS42LTUuOCAwbC01MSA1MS4xLTUxLjEtNTEuMWMtMS42LTEuNi00LjItMS42LTUuOCAwLTEuNiAxLjYtMS42IDQuMiAwIDUuOGw1My45IDUzLjljLjguOCAxLjggMS4yIDIuOSAxLjIgMSAwIDIuMS0uNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIuMS01Ljh6Ii8+PC9zdmc+);
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            transform-origin: center;
            transform-style: preserve-3D;
            transition: all 0.3s ease;

            @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                margin-top: 6px;
            }
        }

        &--white {
            &:after {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjkgMTI5Ij4NCiAgICA8cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTIxLjMgMzQuNmMtMS42LTEuNi00LjItMS42LTUuOCAwbC01MSA1MS4xLTUxLjEtNTEuMWMtMS42LTEuNi00LjItMS42LTUuOCAwLTEuNiAxLjYtMS42IDQuMiAwIDUuOGw1My45IDUzLjljLjguOCAxLjggMS4yIDIuOSAxLjIgMSAwIDIuMS0uNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIuMS01Ljh6Ii8+PC9zdmc+);
            }
        }

        &--wide {
            min-width: 150px;

            &:after {
                right: -65px;
            }
        }

        &[aria-expanded="true"] {
            &:after {
                top: 4px;
                transform: rotateZ(180deg);

                @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    margin-top: -4px;
                }
            }
        }
    }

    &__items {
        position: absolute;
        z-index: 999;
        top: 120%;
        right: -5px;
        border: 1px solid $color-gray-200;
        background: $color-white;
        background-color: $color-white;
        border-radius: 5px;
        opacity: 0;
        transform: translateY(5px);
        transition: transform ease-in 0.2s, opacity ease-in 0.2s,
            visibility ease-in 0.2s;
        visibility: hidden;

        &--outlined-wide {
            z-index: 1;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 0px;
            border: none;
            border: 1px solid $color-primary;
            border-top: none;
            background-color: transparent;
            border-radius: 0px 0px 1.5em 1.5em;
            transform: translateY(0);
            transition: transform ease-in 0.2s, opacity ease-in 0.2s,
                max-height ease-in 0s;

            &[aria-hidden="false"] {
                max-height: 500px;
                opacity: 1;
                transform: translateY(100%);
                transition: transform ease-in 0.2s, opacity ease-in 0.2s,
                    max-height ease-in 1s;
            }
        }

        .language-mobile & {
            top: -200px;
            right: auto;
            left: 20px;
            width: 160px;
        }

        &[aria-hidden="false"] {
            opacity: 1;
            transform: translateY(0);
            transition: transform ease-in 0.2s, opacity ease-in 0.2s,
                visibility ease-in 0.2s;
            visibility: visible;
        }
    }

    &__item {
        padding: 0.75em 1em;
        cursor: pointer;

        &--wide {
            padding: 0.58rem 2em 0.6rem 1.125rem;
        }

        a {
            text-transform: capitalize;
        }

        &:focus,
        &:hover {
            background: $color-lightest-blue;
        }
    }
}
