.page-menu {
}

.page-menu {
    $root: &;

    &__item {
        border-style: solid;
        border-color: $color-gray-200;
        border-top-width: 1px;

        @at-root #{$root}.is-expanded & {
            border-top-color: transparent;
        }

        &.is-expanded {
            .page-menu {
                background-color: $color-lightest-blue;

                .page-menu__item {
                    border-top-color: transparent;
                }

                .page-menu__link {
                    padding-left: 1.5em;
                }
            }
        }

        &#{&}--has-children {
            > .page-menu__link::after {
                background-image: url("../svg/icon-chevron-down-primary.svg");
            }

            &.is-expanded {
                > .page-menu__link::after {
                    background-image: url("../svg/icon-chevron-down-primary.svg");
                    transform: rotate(180deg);
                }
            }
        }

        &:last-child {
            border-bottom-width: 1px;

            .is-expanded & {
                border-bottom-width: 0;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        padding: 0.75em;
        margin-left: auto;

        &.is-active {
            font-weight: bold;
        }

        &::after {
            display: inline-flex;
            width: 1em;
            min-width: 1em;
            height: 1em;
            min-height: 1em;
            align-items: center;
            align-items: center;
            margin-left: 2.25em;
            margin-left: auto;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 1.25em;
            content: "";
            transition: transform 0.2s ease-in-out;
            will-change: transform;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
