.subscribe {
    position: relative;
    max-width: rem(400px);

    &__input {
        width: 100%;
        padding: 0.75em;
        padding-right: 3em;
        border-radius: 25px;
        color: $color-primary;
        font-size: 1rem;
        line-height: 1.5;

        &::-webkit-input-placeholder {
            color: $color-primary;
        }

        &:-ms-input-placeholder {
            color: $color-primary;
        }

        &::placeholder {
            color: $color-primary;
        }
    }

    &:hover {
        text-decoration: underline;
    }

    &__button {
        position: absolute;
        right: 0;
        display: inline-flex;
        width: 3em;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;

        &::after {
            display: inline-block;
            width: 50%;
            height: 50%;
            background-image: escape-svg($data-icon-arrow-right-white);
            background-position: center;
            background-repeat: no-repeat;
            content: "";
            transition: transform 0.2s ease-in-out;
            will-change: transform;
        }
    }
}
