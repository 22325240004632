.contact-card {
    display: flex;
    border-radius: 5px 5px 54px 5px;

    &__image {
        width: 33%;
        min-width: 90px;
        max-width: 90px;
        max-height: 160px;
        margin-right: 1em;

        @include media-breakpoint-up(sm) {
            min-width: 120px;
            max-width: 120px;
        }

        &--rounded {
            overflow: hidden;
            border-radius: 2px 4px 20px 4px;
        }

        &--circle {
            border-radius: 100%;
        }

        &--lg {
            @include media-breakpoint-up(lg) {
                max-width: 120px;
            }
        }
    }
}
