@mixin thumb() {
    width: 20px;
    height: 20px;
    border: 1px solid $color-dark-blue;
    -webkit-appearance: none;
    background: $color-dark-blue;
    border-radius: 15px;
    cursor: pointer;
}

input[type="range"] {
    width: 100%;
    -webkit-appearance: none;
    background: transparent;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 27px;
        margin: 10px 0;
    }

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--val) - var(--min)) / var(--range));
        --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
        width: 100%;
        height: 2px;
        box-sizing: border-box;
        background: linear-gradient($color-gray-300, $color-gray-300) 0 /
            var(--sx) 100% no-repeat $color-dark-blue;
        cursor: pointer;
    }

    &::-webkit-slider-thumb {
        @include thumb();
        margin-top: -9.5px;
    }

    &::-moz-range-track {
        width: 100%;
        height: 2px;
        animate: 0.2s;
        background: $color-dark-blue;
        cursor: pointer;
    }

    &::-moz-range-progress {
        height: 2px;
        border: none;
        background-color: $color-gray-300;
    }

    &::-moz-range-thumb {
        @include thumb();
    }

    &::-ms-track {
        width: 100%;
        height: 2px;
        border-color: transparent;
        animate: 0.2s;
        background: transparent;
        color: transparent;
        cursor: pointer;
    }

    &::-ms-fill-lower {
        background: $color-gray-300;
    }

    &:focus::-ms-fill-lower {
        background: $color-gray-300;
    }

    &::-ms-fill-upper {
        background: $color-dark-blue;
    }

    &:focus::-ms-fill-upper {
        background: $color-dark-blue;
    }

    &::-ms-thumb {
        @include thumb();
        margin-top: 1px;
    }
}
