.lazy {
    $root: &;
    display: block;

    @at-root img#{$root} {
        &:not([src]),
        &:not([src="unknown"]) {
            visibility: hidden;
        }
    }

    &--fade-in {
        opacity: 0.25;
        transition: 500ms opacity ease-out;

        .no-js & {
            opacity: 1;
        }
    }

    &--blur-up {
        -webkit-filter: blur(5px);
        filter: blur(5px);
        transition: filter 500ms;

        &.lazy--loaded {
            filter: blur(0);
        }
    }

    &--loaded {
        opacity: 1;
    }

    .no-js & {
        display: block;
        visibility: visible !important;
    }
}
