.overflow {
    &-auto {
        overflow: auto;
    }

    &-hidden {
        overflow: hidden;
    }

    &-x-hidden {
        overflow-x: hidden;
    }

    &-y-hidden {
        overflow-y: hidden;
    }

    &-visible {
        overflow: visible;
    }

    &-scroll {
        overflow: scroll;
    }

    &-scroll-x-scroll {
        overflow-x: scroll;
    }

    &-scroll-y-scroll {
        overflow-y: scroll;
    }
}
