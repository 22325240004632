.teaser-image {
    width: 100%;
    height: auto;

    &::before {
        padding-top: 80vh;

        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
            padding-top: 100vh;
        }

        @include media-breakpoint-up(sm) {
            padding-top: 55vh;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 65vh;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 760px;
        }
    }
}
