.heatpumprecommendation {
    &__stepcounter {
        position: relative;
        display: block;
        width: 100%;
        height: 4px;
        margin-top: 3em;
        margin-bottom: 4em;
        background: $color-gray-300;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 11.111%;
            height: 4px;
            background: $color-green;
            content: "";
            transition: width ease 0.25s;
        }

        &[data-currentstep="2"] {
            &:before {
                width: 22.222%;
            }
        }

        &[data-currentstep="3"] {
            &:before {
                width: 33.333%;
            }
        }

        &[data-currentstep="4"] {
            &:before {
                width: 44.444%;
            }
        }

        &[data-currentstep="5"] {
            &:before {
                width: 55.555%;
            }
        }

        &[data-currentstep="6"] {
            &:before {
                width: 66.666%;
            }
        }

        &[data-currentstep="7"] {
            &:before {
                width: 77.777%;
            }
        }

        &[data-currentstep="8"] {
            &:before {
                width: 88.111%;
            }
        }

        &[data-currentstep="9"] {
            &:before {
                width: 100%;
            }
        }
    }

    &__step {
        display: none;

        &--current {
            display: block;
        }
    }

    &__heat-prev-next {
        position: relative;
        right: 2em;
        bottom: 5px;
        display: inline-block;
        padding: 4px;
        border: solid $color-primary;
        border-width: 0 2px 2px 0;
        cursor: pointer;
        transform: rotate(135deg);
    }

    &__radio-option {
        label {
            position: relative;
            display: block;
            padding: 1.25rem 0 1.25rem 4.5rem;
            margin-bottom: 1.25rem;
            background: $color-white;
            cursor: pointer;
            font-size: 1.275rem;

            &:before {
                position: absolute;
                top: 50%;
                left: 15px;
                width: 36px;
                height: 36px;
                border: 1px solid $color-primary;
                border-radius: 50%;
                content: "";
                transform: translateY(-50%);
            }
        }

        input[type="radio"] {
            display: none;

            &:checked + label {
                background: $color-green;
                color: $color-white;
                font-weight: 600;

                &:before {
                    border-color: $color-white;
                }
            }

            &[disabled] + label {
                opacity: 0.5;
            }
        }
    }

    &__input-text-right {
        position: relative;
        height: 47px;
        margin-bottom: 4.5rem;

        span {
            position: absolute;
            top: 9px;
            right: 9px;
        }
    }

    .theme > * {
        padding: 0;
        margin-top: 2em;
        margin-bottom: 2em;

        @include media-breakpoint-up($bp-desktop) {
            margin-top: 2em;
            margin-bottom: 2em;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 2em;
            margin-bottom: 2em;
        }
    }
}
