.contactpage {
    .theme--contentarea {
        .theme {
            > * {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .grecaptcha-badge {
        bottom: 3.5em !important;
    }
}
