.quote {
    quotes: "»" "«" "z" "å";
}

.quote::before {
    content: open-quote;

    [data-lang="no"] & {
        content: "«";
    }
}

.quote::after {
    content: close-quote;

    [data-lang="no"] & {
        content: "»";
    }
}
