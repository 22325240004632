ul:not(.prose),
ol:not(.prose) {
    list-style: none;
}

.list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;

    li {
        position: relative;
        margin-bottom: 1ex;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &--horizontal {
        flex-direction: row;

        li {
            margin-right: 1em;
        }
    }

    &--bordered > li {
        border-top-width: 1px;
        margin-top: 0;
        margin-bottom: 0;

        &:last-child {
            border-bottom-width: 1px;
        }
    }

    &__item {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        margin-bottom: 0;
    }

    &--xs > li {
        padding-top: 0.25ex;
        padding-bottom: 0.25ex;
    }

    &--sm > li {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }

    &--md > li {
        padding-top: 0.75em;
        padding-bottom: 0.75em;
    }
}

.list-disc {
    margin-left: 1em;
    list-style-type: disc !important;
}

.list-decimal {
    margin-left: 1em;
    list-style-type: decimal;
}

.list-none {
    list-style: none i !important;
}

.list-inside {
    list-style-position: inside;
}

.numberedlist {
    counter-reset: numberedlist-counter;

    li {
        padding: 0.5em 1em 0em 2em;
        counter-increment: numberedlist-counter;

        &::before {
            position: absolute;
            top: 12px;
            left: 0;
            width: 1.5rem;
            height: 1.5rem;
            padding-top: 1px;
            padding-left: 8px;
            background: $color-primary;
            border-radius: 50%;
            color: $color-white;
            content: counter(numberedlist-counter);
            font-size: 0.9rem;
        }

        &:nth-child(n + 10) {
            &::before {
                padding-left: 5px;
            }
        }

        &:nth-child(n + 20) {
            &::before {
                padding-left: 4px;
            }
        }
    }
}
