.sharegraphblock {
    #periods {
        display: flex;
        margin-bottom: 0.5em;

        > div {
            padding: 0.25em 0.5em;
            border: 1px solid $color-primary;
            cursor: pointer;
            font-size: $text-sm;

            @include media-breakpoint-up(lg) {
                padding: 0.5em 2em;
                font-size: $text-lg;
            }

            &:first-child {
                border-radius: 21px 0 0 21px;
            }

            &:last-child {
                border-radius: 0 21px 21px 0;
            }

            &:hover {
                border-color: $color-primary;
                background-color: $color-primary;
                color: $color-white;
            }
        }
    }

    #info {
        padding: 2rem 0 1.5rem 0;
        border-top: 1px solid $color-gray-300;
        border-bottom: 1px solid $color-gray-300;
        margin-top: 1rem;
    }
}
