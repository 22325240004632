@import "@splidejs/splide/dist/css/themes/splide-default.min.css";

.splide {
    position: relative;

    &__slide {
        border: none !important;
        opacity: 0.7;

        &.is-active {
            opacity: 1;
        }
    }

    &__arrows {
        position: absolute;
        z-index: 999;
        top: 50%;
        width: 100%;
        padding: 0 1em;
        transform: translateY(-50%);

        .sliderblock & {
            top: 25%;

            @include media-breakpoint-up(md) {
                top: 45%;
                right: -0.5em;
                width: 50%;
            }

            @include media-breakpoint-up(lg) {
                top: 50%;
                right: -1px;
                width: 66%;
                padding: 0 1em;
            }
        }
    }

    &__arrow {
        position: relative;
        top: auto;
        width: 34px;
        height: 34px;
        border: none;
        background: $color-gray-400;
        border-radius: 100%;
        color: transparent;
        cursor: pointer;
        opacity: 0.85;
        transform: none;

        &:hover {
            opacity: 1;
        }

        &--prev,
        &--next {
            &:before {
                position: absolute;
                top: 7px;
                left: 9px;
                width: 11px;
                height: 19px;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxMSAxOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSI+DQogIDxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik04Ljc3LjgyOGMuNDItLjQwNSAxLjEtLjQwNSAxLjUyMiAwIC40Mi40MTUuNDIgMS4wNjggMCAxLjQ3M2wtNy42NiA3LjM4NyA3LjY2IDcuMzg1Yy40Mi40MDYuNDIgMS4wNiAwIDEuNDc0LS40Mi40MDUtMS4xLjQwNS0xLjUyMiAwTC41MzQgMTAuNmMtLjI1Ny0uMjUtLjMzNy0uNTkyLS4yNzgtLjkxNC0uMDYtLjMyMi4wMi0uNjY0LjI3OC0uOTEzTDguNzcuODI4eiIvPg0KPC9zdmc+)
                    0 0 no-repeat;
                color: $color-white;
                content: "";
            }
        }

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;

            &:before {
                left: 12px;
                transform: rotate(180deg);
            }
        }
    }

    &__caption {
        position: absolute;
        bottom: 0;
        padding: 0.5em 0.75em;
        background: rgba(0, 0, 0, 0.75);
        color: white;
    }
}
