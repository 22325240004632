.officesearchresult {
    opacity: 0;
    visibility: hidden;

    &--open {
        opacity: 1;
        transition: all ease-in 0.25s;
        visibility: visible;
    }
}

.officesearcherror {
    opacity: 0;
    visibility: hidden;

    &--open {
        margin-top: 2.5rem;
        color: $color-red;
        font-size: $text-lg;
        opacity: 1;
        transition: all ease-in 0.25s;
        visibility: visible;
    }
}
