.backgroundimagetextblock {
    position: relative;
    padding: 0;

    &__content {
        padding: 3em 0;

        @include media-breakpoint-up(md) {
            padding: 4em 0;
        }

        @include media-breakpoint-up(xl) {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 5em 0;
            transform: translate(0, -50%);
        }
    }

    &__media {
        position: absolute;
        width: 100%;
        height: 100%;

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
        }

        &--overlay-dark::after {
            background-color: $overlay-color-dark;

            @include media-breakpoint-up(xl) {
                background-color: transparent;
            }
        }

        &--overlay-light::after {
            background-color: $overlay-color-light;

            @include media-breakpoint-up(xl) {
                background-color: transparent;
            }
        }

        &--overlay-lighter::after {
            background-color: $overlay-color-lighter;

            @include media-breakpoint-up(xl) {
                background-color: transparent;
            }
        }

        &--overlay-blue::after {
            background-color: $overlay-color-blue;

            @include media-breakpoint-up(xl) {
                background-color: transparent;
            }
        }

        @include media-breakpoint-up(xl) {
            position: static;

            &--overlay-dark-dektop::after {
                background-color: $overlay-color-dark;
            }

            &--overlay-light-desktop::after {
                background-color: $overlay-color-light;
            }

            &--overlay-lighter-desktop::after {
                background-color: $overlay-color-lighter;
            }

            &--overlay-blue-desktop::after {
                background-color: $overlay-color-blue;
            }
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
    }
}
