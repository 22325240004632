.tabs {
    &-button {
        padding: 0.5em;
        background: $color-medium-blue;
        border-radius: 6px 6px 0 0;
        color: $color-white;
        font-size: $text-sm;
        transition: background ease 0.25s;

        @include media-breakpoint-up(md) {
            padding: 0.75em 0.5em;
            font-size: $text-d-base;
        }

        &[aria-expanded="true"],
        &:hover {
            background: $color-dark-blue;
            color: $color-white;
        }
    }

    &-body {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transition: opacity ease 0.33s, visibility ease 0.33s;
        visibility: hidden;

        &[aria-hidden="false"] {
            max-height: 100%;
            opacity: 1;
            visibility: visible;
        }
    }
}
