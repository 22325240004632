.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;

    &__item {
        padding: 0 0.5rem;
    }

    &__link {
        padding: 0.5ex 1ex;
        text-transform: uppercase;
        transition: background 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            border 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:focus,
        &:hover {
            background: $color-lightest-blue;
        }

        &--selected {
            background: $color-lightest-blue;
        }

        &--next {
            padding: 0.5ex 1.2ex;

            svg {
                position: absolute;
                top: 5px;
                transform: rotate(270deg);
            }
        }

        &--previous {
            padding: 0.5ex 1.2ex;

            svg {
                position: absolute;
                top: 5px;
                transform: rotate(90deg);
            }
        }
    }
}
