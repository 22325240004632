.accordion {
    &__inner {
        overflow: hidden;
        height: 180px;

        @include media-breakpoint-up(xl) {
            height: 200px;
        }

        .accordion--open & {
            height: auto;
            transition: height ease-in 0.25s;
        }
    }

    &__button {
        .accordion--open & {
            display: none;
        }

        &:after,
        &:before {
            position: absolute;
            top: 50%;
            width: 15%;
            height: 1px;
            background: $color-gray-500;
            content: "";
            transform: translateY(-50%);

            @include media-breakpoint-up(md) {
                width: 30%;
            }

            @include media-breakpoint-up(lg) {
                width: 35%;
            }
        }

        &:after {
            right: 0;
        }

        &:before {
            left: 0;
        }
    }
}

.accordion-dropdown {
    &__button::hover {
        cursor: pointer;
    }

    &__content[aria-hidden="false"] {
        padding-top: 1.25em;
        padding-bottom: 2em;
        transition: opacity 0.5s;
    }

    &__content[aria-hidden="true"] {
        height: 0;
        opacity: 0;
        visibility: hidden;
    }
}
