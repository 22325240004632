.modal {
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background: rgba(0, 0, 0, 0.14);

    &--show {
        position: fixed;
        display: block;
    }

    &__inner {
        position: absolute;
        z-index: 1000;
        top: 50%;
        left: 50%;
        width: 100%;
        min-width: 320px;
        max-width: 100vw;
        height: 80vh;
        padding: 1.5em 1em;
        background: $color-white;
        border-radius: 4px;
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14);
        overflow-x: auto;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up(md) {
            width: auto;
            min-width: 640px;
            max-width: 700px;
            height: 70vh;
            padding: 3em 1.5em;
        }

        @include media-breakpoint-up(lg) {
            min-width: 800px;
        }
    }

    &__inner-spacer {
        overflow: auto;
        height: 100%;
        padding: 1.5em 1em;
    }

    &__close {
        position: absolute;
        top: 8px;
        right: 0;
        display: block;
        width: 30px;
        height: 30px;
        border: 2px solid $color-black;
        background: $color-white;
        border-radius: 100%;
        cursor: pointer;

        span {
            &:before {
                position: absolute;
                top: 12px;
                display: block;
                width: 27px;
                height: 1px;
                border: 1px solid $color-black;
                background: $color-black;
                content: "";
            }

            &:first-child {
                &:before {
                    transform: rotate(135deg);
                }
            }

            &:last-child {
                &:before {
                    transform: rotate(225deg);
                }
            }
        }
    }
}
