.timeline {
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        &:nth-child(1).timeline__item--long-text,
        &:nth-last-child(1).timeline__item--long-text {
            align-items: flex-start;
        }

        &:not(:nth-last-child(1))::after {
            position: absolute;
            top: 0;
            bottom: -2rem;
            left: 26px;
            width: 4px;
            background-color: $color-medium-blue;
            content: "";

            @include media-breakpoint-up(md) {
                left: 38px;
            }
        }
    }

    &__year {
        z-index: 1;
        display: flex;
        width: 60px;
        min-width: 60px;
        height: 60px;
        min-height: 60px;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        background-color: $color-medium-blue;
        border-radius: 50px;
        color: $color-white;

        @include media-breakpoint-up(md) {
            width: 80px;
            min-width: 80px;
            height: 80px;
            min-height: 80px;
        }
    }

    &__text {
        overflow-wrap: break-word;
    }
}
