.table-wrapper {
    overflow-x: auto;
}

table:not(.table--reset):not(.table--person):not(table.CookieDeclarationTable) {
    width: 100% !important;
    height: auto !important;

    th {
        color: $color-primary;
        font-weight: 600;
        text-align: left;
    }

    td,
    th {
        padding: 1rem 0;
        border-bottom: 1px solid $color-gray-300;
        font-size: $text-lg;
        vertical-align: baseline;
        vertical-align: -webkit-baseline-middle;
        vertical-align: -moz-middle-with-baseline;

        @include media-breakpoint-down(sm) {
            width: auto !important;
            height: auto !important;
            white-space: nowrap;
        }

        &:first-child {
            padding-left: 0.75rem;
        }

        &:last-child {
            padding-right: 0.75rem;
        }
    }

    tr {
        &:hover {
            background-color: $color-gray-100;
        }
    }
}

table.table--reset {
    width: 100% !important;
    height: auto !important;

    td,
    tr {
        vertical-align: top;
    }
}

table.table--person {
    width: 100% !important;
    height: auto !important;

    td,
    tr {
        vertical-align: top;
    }

    td {
        width: 50% !important;
        height: auto !important;

        &:first-of-type {
            width: 25% !important;

            @include media-breakpoint-up(sm) {
                width: 50% !important;
            }
        }

        img {
            width: 100%;
            padding-right: 0.5em;
        }
    }
}
