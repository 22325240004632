@include font-sizes($font-sizes-map);

h1 + .preamble {
    margin-top: 1em;
}

.h1,
h1 {
    font-size: $text-4xl;

    @include media-breakpoint-up($bp-desktop-fonts) {
        font-size: $text-d-4xl;
    }
}

.h2,
h2 {
    font-size: $text-3xl;

    @include media-breakpoint-up($bp-desktop-fonts) {
        font-size: $text-d-3xl;
    }
}

.h3,
h3 {
    font-size: $text-2xl;

    @include media-breakpoint-up($bp-desktop-fonts) {
        font-size: $text-d-2xl;
    }
}

.preamble {
    font-size: $text-xl;
    line-height: $leading-relaxed;

    @include media-breakpoint-up($bp-desktop-fonts) {
        font-size: $text-d-xl;
    }
}

.introduction {
    line-height: $leading-relaxed;
}

.preamble-text-base {
    font-size: $text-base;
    line-height: $leading-relaxed;

    @include media-breakpoint-up($bp-desktop-fonts) {
        font-size: $text-d-base;
    }
}

b,
strong,
.strong {
    font-weight: $font-weight-bold;
}

@each $weight, $value in $font-weights {
    .font-#{$weight} {
        font-weight: $value;
    }
}

.text {
    &-italic {
        font-style: italic !important;
    }

    &-normal {
        font-style: normal !important;
    }

    &-monospace {
        font-family: $font-family-monospace !important;
    }
    // Alignment
    &-justify {
        text-align: justify !important;
    }

    &-wrap {
        white-space: normal !important;
    }

    &-nowrap {
        white-space: nowrap !important;
    }

    &-break {
        overflow-wrap: break-word !important;
        word-break: break-word !important;
    }

    &-truncate {
        @include text-truncate;
    }
    // Transformation
    &-lowercase {
        text-transform: lowercase !important;
    }

    &-uppercase {
        text-transform: uppercase !important;
    }

    &-capitalize {
        text-transform: capitalize !important;
    }

    &-no-transform {
        text-transform: none !important;
    }

    &-hide {
        @include text-hide($ignore-warning: true);
    }

    &-decoration-none {
        text-decoration: none !important;
    }

    &-break {
        overflow-wrap: break-word !important;
        word-break: break-word !important; // IE & < Edge 18
    }

    &-underline {
        text-decoration: underline;
    }

    &-line-through {
        text-decoration: line-through;
    }

    // Reset
    &-reset {
        color: inherit !important;
    }
    // Responsive alignment
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            &#{$infix}-left {
                text-align: left !important;
            }

            &#{$infix}-right {
                text-align: right !important;
            }

            &#{$infix}-center {
                text-align: center !important;
            }
        }
    }
}

.break {
    &-normal {
        overflow-wrap: normal;
        word-break: normal;
    }

    &-words {
        overflow-wrap: break-word;
    }

    &-all {
        word-break: break-all;
    }
}

@each $leading, $value in $leadings {
    .leading-#{$leading} {
        line-height: $value !important;
    }
}
